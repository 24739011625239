<script>
import { defineComponent } from 'vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import AntInput from '@/components/AntInput.vue';
import { SWOT_SCENARIOS } from '@/modules/modules';
import { mapGetters, mapState } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import { enable } from 'core-js/internals/internal-metadata';
import GisViewerMap from '@/components/Modules/GisViewer/GisViewerMap.vue';
import { GIS_VIEWER_WIDGETS } from '@/components/Modules/GisViewer';
import { createUUID } from '@/services/uuid-helper';
import SwotTableRow from '@/modules/SwotTableRow.vue';

export default defineComponent({
  name: 'SwotScenarios',
  components: {
    SwotTableRow,
    GisViewerMap,
    DeleteDialog,
    AntLoading,
    AntInput,
    PanelResizable,
    ModuleNavigationBar,
  },
  mixins: [InputRulesMixin],
  data: () => {
    return {
      stakeholderCreateName: null,
      stakeholderCreateMenu: false,
      stakeholderToDelete: null,

      scenarioCreateName: null,
      scenarioCreateMenu: false,
      scenarioToDelete: null,

      swotDialog: false,
      swotRecord: {},
      swotRecordToDelete: null,

      rightSidebar: true,
      isLinkSwotToScenarioMode: false,

      showLayers: false,
      mapWidgets: {
        [GIS_VIEWER_WIDGETS.RFI]: true,
      },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'stakeHolderAnalysisStakeholders',
      'stakeholderAnalysisSwotInputRecords',
      'stakeholderAnalysisSwotRecords',
      'stakeholderAnalysisSwotTotalWeight',
      'selectedStakeholder',
      'sbsRecords',
    ]),
    ...mapGetters({
      status: 'swotScenarios/status',
      scenarios: 'swotScenarios/scenarios',
      selectedScenarioSwotIds: 'swotScenarios/selectedScenarioSwotIds',
    }),
    ...mapGetters('gisViewer', {
      viewer: 'viewer',
      showSketchPanel: 'showSketchPanel',
    }),
    ...mapState({
      selectedScenario: (state) => state.swotScenarios.selectedScenario,
      selectedScenarioId: (state) => state.swotScenarios.selectedScenario?.id,
    }),

    selectedStakeholderId() {
      return this.selectedStakeholder?.id;
    },
    swotStrengths() {
      let records = this.stakeholderAnalysisSwotRecords
        .filter((record) => record.type === 'strength')
        .sort((a, b) => b.weight - a.weight);
      if (this.selectedScenario && !this.isLinkSwotToScenarioMode) {
        records = records.filter((record) =>
          this.selectedScenarioSwotIds.some((swotId) => swotId === record.id)
        );
      }
      return records;
    },
    swotWeakness() {
      let records = this.stakeholderAnalysisSwotRecords
        .filter((record) => record.type === 'weakness')
        .sort((a, b) => b.weight - a.weight);
      if (this.selectedScenario && !this.isLinkSwotToScenarioMode) {
        records = records.filter((record) =>
          this.selectedScenarioSwotIds.some((swotId) => swotId === record.id)
        );
      }
      return records;
    },
    swotOpportunities() {
      let records = this.stakeholderAnalysisSwotRecords
        .filter((record) => record.type === 'opportunity')
        .sort((a, b) => b.weight - a.weight);
      if (this.selectedScenario && !this.isLinkSwotToScenarioMode) {
        records = records.filter((record) =>
          this.selectedScenarioSwotIds.some((swotId) => swotId === record.id)
        );
      }
      return records;
    },
    swotThreats() {
      let records = this.stakeholderAnalysisSwotRecords
        .filter((record) => record.type === 'threat')
        .sort((a, b) => b.weight - a.weight);
      if (this.selectedScenario && !this.isLinkSwotToScenarioMode) {
        records = records.filter((record) =>
          this.selectedScenarioSwotIds.some((swotId) => swotId === record.id)
        );
      }
      return records;
    },
  },
  watch: {
    selectedScenario: {
      handler(scenario) {
        if (scenario) {
          if (scenario.drawing?.file) {
            this.$store.dispatch('gisViewer/loadNewGraphics', {
              title: scenario.name,
              json: decodeURIComponent(escape(atob(scenario.drawing.file))),
            });
          } else {
            this.$store.dispatch('gisViewer/clearCustomGraphics');
          }
        } else {
          this.$store.dispatch('gisViewer/clearCustomGraphics');
          this.isLinkSwotToScenarioMode = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    enable,
    stringToConstantColor,
    loadData() {
      this.$store.dispatch('swotScenarios/loadModuleData', {
        projectId: this.project.id,
        moduleId: this.project.modules.find(
          (module) => module.route === SWOT_SCENARIOS
        ).id,
        sessionId: this.$route.params.sessionId ?? null,
      });
      this.$store.dispatch('loadSbsRecords', { projectId: this.project.id });
    },
    async createStakeholder() {
      await this.$store.dispatch(
        'createStakeholder',
        this.stakeholderCreateName
      );
      this.stakeholderCreateName = null;
      this.stakeholderCreateMenu = false;
    },
    async createScenario() {
      await this.$store.dispatch(
        'swotScenarios/createScenario',
        this.scenarioCreateName
      );
      this.scenarioCreateName = null;
      this.scenarioCreateMenu = false;
    },
    async deleteStakeholder() {
      await this.$store.dispatch(
        'deleteStakeholder',
        this.stakeholderToDelete.id
      );
      this.stakeholderToDelete = null;
    },
    async deleteScenario() {
      await this.$store.dispatch(
        'swotScenarios/deleteScenario',
        this.scenarioToDelete.id
      );
      this.scenarioToDelete = null;
    },
    async createSwotRecord() {
      if (this.$refs['swot-form'].validate()) {
        try {
          this.swotRecord.stakeholder_id = this.selectedStakeholder.id;
          this.swotRecord.weight = parseInt(this.swotRecord.weight);
          await this.$store.dispatch('createSwotRecord', this.swotRecord);
          this.swotDialog = false;
          this.swotRecord = {};
          this.$refs['swot-form'].reset();
        } catch (e) {
          this.$store.commit('showNotification', {
            content: e.message,
            color: 'error',
          });
        }
      }
    },
    async deleteSwotRecord() {
      await this.$store.dispatch(
        'deleteSwotRecord',
        this.swotRecordToDelete.id
      );
      this.swotRecordToDelete = null;
    },
    updateStakeholder(column, value) {
      let body = {};
      body[column] = this.selectedStakeholder[column] + value;

      this.$store.dispatch('updateStakeholder', body);
    },
    isLinkedRecord(id) {
      return this.selectedScenarioSwotIds.some((swotId) => swotId === id);
    },

    toggleLayerList(state) {
      this.showLayers = state;
      this.viewer.widgets.layerList.visible = this.showLayers;
    },
    toggleSketchPanelShow(state) {
      if (state) {
        this.$store.commit('gisViewer/toggle_sketch_panel', { value: state });
        // this.viewer.widgets[GIS_VIEWER_WIDGETS.SKETCH] = undefined;
        this.$store.dispatch('gisViewer/setViewerProp', {
          key: `widgets.${GIS_VIEWER_WIDGETS.SKETCH}`,
          value: undefined,
        });
        this.$store.dispatch('gisViewer/reloadWidgets');
      }
      if (!state) {
        this.$store.commit('gisViewer/toggle_sketch_panel', { value: state });
        this.$store.dispatch('gisViewer/removeWidget', {
          key: GIS_VIEWER_WIDGETS.SKETCH,
        });
      }
    },
    async saveGraphics() {
      const items = this.viewer.sketchLayers[0]?.graphics?.items;
      let data = [];
      if (items?.length) {
        data = items.map((i) => ({
          symbol: {
            type: i.symbol.type,
            size: i.symbol.size,
            style: i.symbol.style,
            color: i.symbol.color.toRgba(),
            width: i.symbol.width,
            outline: i.symbol.outline
              ? {
                  type: i.symbol.outline.type,
                  color: i.symbol.outline.color.toRgba(),
                  width: i.symbol.outline.width,
                }
              : undefined,
          },
          geometry: {
            spatialReference: i.geometry.spatialReference,
            type: i.geometry.type,
            paths: i.geometry.paths,
            rings: i.geometry.rings,
            latitude: i.geometry.latitude,
            longitude: i.geometry.longitude,
          },
        }));
      }
      await this.$store.dispatch('swotScenarios/updateScenario', {
        recordId: this.selectedScenarioId,
        data: {
          drawing: {
            name: createUUID(),
            extension: 'json',
            data: btoa(unescape(encodeURIComponent(JSON.stringify(data)))),
          },
        },
      });
      this.toggleSketchPanelShow(false);
    },
  },
});
</script>

<template>
  <div class="d-flex flex-column overflow-y-auto">
    <module-navigation-bar title="SWOT Scenarios">
      <template #module-nav-actions>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              :class="{
                'mdi-spin': status === 'loading',
              }"
              class="ant-icon"
              @click="loadData"
              v-on="on"
            >
              mdi-refresh
            </v-icon>
          </template>
          <span> {{ $t('system.taskOverview.reload') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on: tooltip, attrs }">
            <div>
              <v-icon
                v-if="isLinkSwotToScenarioMode"
                key="swot-link-action-close"
                class="ant-icon mx-2"
                v-bind="attrs"
                @click="isLinkSwotToScenarioMode = false"
                v-on="{ ...tooltip }"
              >
                mdi-link-lock
              </v-icon>
              <v-icon
                v-else-if="!isLinkSwotToScenarioMode"
                key="swot-link-action"
                class="sidebar-item-icon ant-icon mx-2"
                v-bind="attrs"
                @click="isLinkSwotToScenarioMode = true"
                v-on="{ ...tooltip }"
              >
                mdi-link
              </v-icon>
            </div>
          </template>
          <span>Link SWOTs</span>
        </v-tooltip>
      </template>
    </module-navigation-bar>
    <div
      v-if="status === 'success'"
      class="flex-grow-1 d-flex overflow-y-auto overflow-x-hidden pos-rel"
    >
      <!-- ------------- Left panel -------------------------------------------------------------- -->
      <!-- ------------- Left panel -------------------------------------------------------------- -->
      <!-- ------------- Left panel -------------------------------------------------------------- -->
      <panel-resizable
        :collapsible="false"
        :default-width="400"
        :min-width="100"
        class="ant-glass-background full-height ant-border-right radius-0 overflow-hidden"
        side="left"
        style="border-radius: 0"
      >
        <div class="d-flex flex-column flex-grow-1 overflow-hidden">
          <v-subheader class="px-2 my-2" style="height: auto">
            <div class="text-subtitle-2 py-1">Scenarios</div>
            <v-spacer />
            <v-menu
              v-model="scenarioCreateMenu"
              :close-on-content-click="false"
            >
              <template #activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template #activator="{ on: tooltip }">
                    <v-icon
                      class="ant-icon"
                      dense
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>New scenario</span>
                </v-tooltip>
              </template>
              <v-card class="pa-2">
                <ant-input label="Name" top-margin="mt-0">
                  <template #input-field>
                    <v-text-field
                      v-model="scenarioCreateName"
                      autofocus
                      dense
                      filled
                      hide-details
                      placeholder="name"
                      single-line
                      @keydown.esc="
                        scenarioCreateMenu = false;
                        scenarioCreateName = null;
                      "
                      @keydown.enter="createScenario"
                    />
                  </template>
                </ant-input>
              </v-card>
            </v-menu>
          </v-subheader>
          <delete-dialog
            :dialog="scenarioToDelete !== null"
            :title="`Are you sure you want to delete ${scenarioToDelete?.name}`"
            @closeDialog="scenarioToDelete = null"
            @deleteAction="deleteScenario"
          />
          <v-list dense>
            <v-list-item-group :value="selectedScenarioId" color="primary">
              <v-list-item
                v-for="scenario in scenarios"
                :key="scenario.id"
                :value="scenario.id"
                class="sidebar-item"
                @click="
                  $store.commit(
                    'swotScenarios/module_swot_scenarios_toggle_scenario',
                    scenario
                  )
                "
              >
                <v-list-item-icon>
                  <v-icon :color="stringToConstantColor(scenario.id)">
                    mdi-checkbox-blank-circle
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title> {{ scenario.name }}</v-list-item-title>
                <v-icon
                  class="sidebar-item-icon ant-icon"
                  dense
                  @click.stop="scenarioToDelete = scenario"
                  >mdi-delete
                </v-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div class="d-flex flex-column flex-grow-1 overflow-hidden">
          <v-subheader class="px-2 my-2" style="height: auto">
            <div class="text-subtitle-2 py-1">Stakeholders</div>
          </v-subheader>
          <v-list dense>
            <v-list-item-group :value="selectedStakeholderId" color="primary">
              <v-list-item
                v-for="stakeholder in stakeHolderAnalysisStakeholders"
                :key="stakeholder.id"
                :value="stakeholder.id"
                class="sidebar-item"
                @click="
                  $store.commit(
                    'module_stake_holder_analysis_toggle_stakeholder',
                    stakeholder
                  )
                "
              >
                <v-list-item-icon>
                  <v-icon :color="stringToConstantColor(stakeholder.id)"
                    >mdi-checkbox-blank-circle
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title> {{ stakeholder.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </panel-resizable>
      <!-- ------------- Content ----------------------------------------------------------------- -->
      <!-- ------------- Content ----------------------------------------------------------------- -->
      <!-- ------------- Content ----------------------------------------------------------------- -->
      <div class="flex-grow-1 d-flex flex-column overflow-y-auto">
        <div class="pa-2">
          <v-tooltip key="gis-viewer.layers" bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :color="showLayers ? 'primary' : ''"
                :ripple="false"
                class="mx-2"
                icon
                small
                v-bind="attrs"
                @click="toggleLayerList(!showLayers)"
                v-on="on"
              >
                <v-icon> mdi-layers</v-icon>
              </v-btn>
            </template>
            <span>Display Layers</span>
          </v-tooltip>
          <v-tooltip key="gis-viewer.sketch" bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :color="showSketchPanel ? 'primary' : ''"
                :disabled="!selectedScenarioId"
                :ripple="false"
                class="mx-2"
                icon
                small
                v-bind="attrs"
                @click="toggleSketchPanelShow(!showSketchPanel)"
                v-on="on"
              >
                <v-icon> mdi-shape-outline</v-icon>
              </v-btn>
            </template>
            <span>Sketch Panel</span>
          </v-tooltip>
          <v-tooltip key="gis-viewer.sketch.save" bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="!selectedScenarioId"
                :ripple="false"
                class="mx-2"
                icon
                small
                v-bind="attrs"
                @click="saveGraphics()"
                v-on="on"
              >
                <v-icon
                  v-if="$wait.is(`updateScenario.${selectedScenarioId}`)"
                  class="mdi-spin"
                >
                  mdi-loading
                </v-icon>
                <v-icon v-else>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Save sketch</span>
          </v-tooltip>
        </div>
        <gis-viewer-map :widgets="mapWidgets" class="flex-grow-1" />
      </div>
      <!-- ------------- Right panel ------------------------------------------------------------- -->
      <!-- ------------- Right panel ------------------------------------------------------------- -->
      <!-- ------------- Right panel ------------------------------------------------------------- -->
      <panel-resizable
        v-if="rightSidebar"
        :default-width="600"
        :min-width="400"
        class="ant-glass-background full-height ant-border-right radius-0 overflow-hidden"
        side="right"
        @collapse="rightSidebar = false"
      >
        <div class="d-flex flex-column px-2 py-2 flex-grow-1 right-sidebar">
          <delete-dialog
            :dialog="swotRecordToDelete !== null"
            :title="`Are you sure you want to delete id '${swotRecordToDelete?.friendly_id}'`"
            @closeDialog="swotRecordToDelete = null"
            @deleteAction="deleteSwotRecord"
          />
          <Transition name="simple-fade">
            <div class="flex-grow-1">
              <div
                class="ant-glass-background d-flex flex-column overflow-hidden swot-card"
              >
                <div
                  :style="{ background: 'var(--v-success-lighten4)' }"
                  class="px-5 py-2 d-flex align-center justify-center ant-border-bottom pos-rel"
                >
                  Strengths
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <div class="fs-12 ml-2" v-bind="attrs" v-on="on">
                        ({{
                          swotStrengths.reduce(
                            (partial_sum, a) =>
                              partial_sum + parseInt(a.weight),
                            0
                          )
                        }})
                      </div>
                    </template>
                    <span>Total weight</span>
                  </v-tooltip>
                  <div
                    class="pos-abs full-height px-2 d-flex align-center swot-card-actions"
                  ></div>
                </div>
                <div class="pa-2 overflow-y-auto flex-grow-1">
                  <SwotTableRow
                    v-for="record in swotStrengths"
                    :key="record.id"
                    :is-link-mode="isLinkSwotToScenarioMode"
                    :is-linked="isLinkedRecord(record.id)"
                    :loading="
                      $wait.is(
                        `swotToScenario-${record.id}-${selectedScenarioId}`
                      )
                    "
                    :record="record"
                    :selected-stakeholder="selectedStakeholder"
                  />
                </div>
              </div>
              <div
                class="ant-glass-background d-flex flex-column overflow-hidden swot-card"
              >
                <div
                  :style="{ background: 'var(--v-warning-lighten4)' }"
                  class="px-5 py-2 d-flex align-center justify-center ant-border-bottom"
                >
                  Weakness
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <div class="fs-12 ml-2" v-bind="attrs" v-on="on">
                        ({{
                          swotWeakness.reduce(
                            (partial_sum, a) =>
                              partial_sum + parseInt(a.weight),
                            0
                          )
                        }})
                      </div>
                    </template>
                    <span>Total weight</span>
                  </v-tooltip>
                </div>
                <div class="pa-2 overflow-y-auto flex-grow-1">
                  <SwotTableRow
                    v-for="record in swotWeakness"
                    :key="record.id"
                    :is-link-mode="isLinkSwotToScenarioMode"
                    :is-linked="isLinkedRecord(record.id)"
                    :loading="
                      $wait.is(
                        `swotToScenario-${record.id}-${selectedScenarioId}`
                      )
                    "
                    :record="record"
                    :selected-stakeholder="selectedStakeholder"
                  />
                </div>
              </div>
              <div
                class="ant-glass-background d-flex flex-column overflow-hidden swot-card"
              >
                <div
                  :style="{ background: 'var(--v-info-lighten4)' }"
                  class="px-5 py-2 d-flex align-center justify-center ant-border-bottom"
                >
                  Opportunities
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <div class="fs-12 ml-2" v-bind="attrs" v-on="on">
                        ({{
                          swotOpportunities.reduce(
                            (partial_sum, a) =>
                              partial_sum + parseInt(a.weight),
                            0
                          )
                        }})
                      </div>
                    </template>
                    <span>Total weight</span>
                  </v-tooltip>
                </div>
                <div class="pa-2 overflow-y-auto flex-grow-1">
                  <SwotTableRow
                    v-for="record in swotOpportunities"
                    :key="record.id"
                    :is-link-mode="isLinkSwotToScenarioMode"
                    :is-linked="isLinkedRecord(record.id)"
                    :loading="
                      $wait.is(
                        `swotToScenario-${record.id}-${selectedScenarioId}`
                      )
                    "
                    :record="record"
                    :selected-stakeholder="selectedStakeholder"
                  />
                </div>
              </div>
              <div
                class="ant-glass-background d-flex flex-column overflow-hidden swot-card"
              >
                <div
                  :style="{ background: 'var(--v-error-lighten4)' }"
                  class="px-5 py-2 d-flex align-center justify-center ant-border-bottom"
                >
                  Threats
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <div class="fs-12 ml-2" v-bind="attrs" v-on="on">
                        ({{
                          swotThreats.reduce(
                            (partial_sum, a) =>
                              partial_sum + parseInt(a.weight),
                            0
                          )
                        }})
                      </div>
                    </template>
                    <span>Total weight</span>
                  </v-tooltip>
                </div>
                <div class="pa-2 overflow-y-auto flex-grow-1">
                  <SwotTableRow
                    v-for="record in swotThreats"
                    :key="record.id"
                    :is-link-mode="isLinkSwotToScenarioMode"
                    :is-linked="isLinkedRecord(record.id)"
                    :loading="
                      $wait.is(
                        `swotToScenario-${record.id}-${selectedScenarioId}`
                      )
                    "
                    :record="record"
                    :selected-stakeholder="selectedStakeholder"
                  />
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </panel-resizable>
      <div v-else class="right-sidebar-toggle full-height d-flex align-center">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              class="menu-toggle-action"
              large
              style="pointer-events: all"
              v-bind="attrs"
              @click="rightSidebar = true"
              v-on="on"
            >
              mdi-chevron-left
            </v-icon>
          </template>
          <span>{{ $t('system.sbsTree.toggleSidebar') }}</span>
        </v-tooltip>
      </div>
    </div>
    <div v-else class="flex-grow-1 d-flex align-center justify-center">
      <ant-loading />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.right-sidebar {
  .swot-card {
    height: 25%;

    .swot-card-actions {
      right: 0;
    }
  }
}

.right-sidebar-toggle {
  position: absolute;
  z-index: 100;
  right: 0;
  pointer-events: none;

  .menu-toggle-action {
    background-color: transparent;
    border-color: transparent;
    transform: translateX(30%);

    &:hover {
      color: var(--v-primary-base);
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(2px);
      border-radius: 5px;
      border-width: 1px;
      border-color: rgba(126, 126, 126, 0.2);
      border-style: solid;
      transform: translateX(-5%);
    }
  }
}

.swot-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;

  .swot-card {
    min-height: 250px;
  }
}

.sidebar-item {
  .sidebar-item-icon {
    opacity: 0;
    transition: 200ms;
  }

  &:hover {
    .sidebar-item-icon {
      opacity: 1;
    }
  }
}
</style>
