<script>
import { defineComponent } from 'vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { FIT_GAP } from '@/modules/modules';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import VueMetaMixin from '@/Mixins/VueMetaMixin';
import { categories } from '@/modules/fit-gap/constants';
import FitGapCreateForm from '@/modules/fit-gap/FitGapForm.vue';
import FitGapForm from '@/modules/fit-gap/FitGapForm.vue';
import FitGapItem from '@/modules/fit-gap/FitGapItem.vue';
import FitGapDialog from '@/modules/fit-gap/Tasks/FitGapDialog.vue';
import FitGapTaskItem from '@/modules/fit-gap/Tasks/FitGapTaskItem.vue';

export default defineComponent({
  name: 'FitGap',
  components: {
    FitGapForm,
    FitGapTaskItem,
    AntLoading,
    ModuleNavigationBar,
    FitGapCreateForm,
    FitGapItem,
    FitGapDialog,
  },
  mixins: [InputRulesMixin, VueMetaMixin],
  data: () => {
    return {
      moduleName: 'FitGap',
      panel: [0, 1, 2, 3],
      createMode: false,
      selectedItem: null,
      showFitGapTaskDialog: false,
      categories: categories,
      selectedTask: null,
    };
  },
  computed: {
    ...mapGetters(['project']),
    ...mapGetters({
      status: 'fitGap/status',
      recordsByCategories: 'fitGap/recordsByCategories',
      tasks: 'fitGap/tasks',
    }),
    itemTasks() {
      return this.tasks[this.selectedItem?.id] || [];
    },
  },
  watch: {
    selectedItem: {
      handler(value) {
        if (value?.id) {
          this.fetchItemTasks(value.id);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    stringToConstantColor,
    onClickItem(item) {
      if (this.selectedItem?.id === item.id) {
        this.selectedItem = null;
        return;
      }
      this.selectedItem = item;
    },
    loadData() {
      this.$store.dispatch('fitGap/loadModuleData', {
        projectId: this.project.id,
        moduleId: this.project.modules.find(
          (module) => module.route === FIT_GAP
        ).id,
        sessionId: this.$route.params.sessionId ?? null,
      });
    },
    fetchItemTasks(id) {
      this.$store.dispatch('fitGap/fetchTasks', id);
    },
    onFitGapTaskDialogClose() {
      this.showFitGapTaskDialog = false;
      this.selectedTask = null;
    },
    onSelectTask(task) {
      this.selectedTask = task;
      this.showFitGapTaskDialog = true;
    },
  },
});
</script>

<template>
  <div class="d-flex flex-column overflow-y-auto">
    <module-navigation-bar title="Fit Gap">
      <template #module-nav-actions>
        <div class="d-flex full-width">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                :loading="status === 'loading'"
                icon
                @click="loadData"
                v-on="on"
              >
                <v-icon> mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('modules.fitGap.reload') }}</span>
          </v-tooltip>
        </div>
      </template>
    </module-navigation-bar>
    <div
      v-if="status !== 'success2'"
      class="flex-grow-1 d-flex overflow-y-auto"
    >
      <div class="flex-grow-1 d-flex flex-column overflow-y-auto">
        <transition-group
          class="pa-5 overflow-y-auto flex-grow-1"
          name="simple-fade"
          tag="div"
        >
          <div key="panel" class="d-flex flex-column overflow-hidden">
            <v-row>
              <v-col md="8">
                <div
                  v-if="$wait.is('module.fit-gap.data.get')"
                  class="d-flex full-height align-center justify-center"
                >
                  <ant-loading />
                </div>
                <div v-else>
                  <v-card class="pa-0 mb-2" elevation="0">
                    <div
                      class="d-flex justify-space-between align-content-center py-4 columns"
                    >
                      <div class="d-flex">
                        <div
                          class="px-0 pl-6 d-flex flex-column justify-center align-center"
                        >
                          <span>Priority</span>
                        </div>
                      </div>
                      <div class="d-flex flex-grow-1 columns">
                        <div
                          class="column-center px-0 d-flex justify-start align-center"
                        >
                          Desired situation
                        </div>
                        <div
                          class="column-center px-0 d-flex justify-start align-center"
                        >
                          Current situation
                        </div>
                        <div
                          class="column-center px-0 d-flex justify-start align-center"
                        >
                          Gap description
                        </div>
                      </div>
                      <div
                        class="d-flex fit-column mr-6 justify-center align-center"
                      >
                        <div
                          class="d-flex pr-4 align-center justify-end flex-grow-1"
                        >
                          Fit
                        </div>
                      </div>
                    </div>
                  </v-card>
                  <v-expansion-panels v-model="panel" class="pb-1" multiple>
                    <v-expansion-panel
                      v-for="category in categories"
                      :key="category"
                    >
                      <v-expansion-panel-header>
                        {{
                          `${category}${
                            recordsByCategories &&
                            recordsByCategories[category] &&
                            recordsByCategories[category].length
                              ? `: ${recordsByCategories[category].length}`
                              : ''
                          }`
                        }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template
                          v-if="recordsByCategories?.[category]?.length"
                        >
                          <fit-gap-item
                            v-for="item in recordsByCategories[category].sort(
                              (a, b) => a.friendly_id - b.friendly_id
                            )"
                            :key="item.id"
                            :selected="selectedItem?.id === item.id"
                            :value="item"
                            @click="onClickItem(item)"
                          />
                        </template>
                        <v-card v-else class="pa-0 mt-2" elevation="0">
                          <div
                            class="d-flex justify-space-between align-content-center"
                          >
                            <v-card-text
                              >{{ $t('modules.fitGap.noRecords') }}
                            </v-card-text>
                          </div>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-col>
              <v-col md="4">
                <fit-gap-form
                  :selected-item="selectedItem"
                  @closeItem="selectedItem = null"
                />
                <div v-if="selectedItem" key="Tasks" class="pb-2">
                  <v-card class="pa-0">
                    <div
                      class="d-flex justify-space-between align-content-center"
                    >
                      <div class="d-flex">
                        <v-card-actions>
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-icon
                                key="add"
                                class="ant-icon mx-1"
                                @click="selectedItem = null"
                                v-on="on"
                              >
                                mdi-close
                              </v-icon>
                            </template>
                            <span>{{ $t('system.tasks.close') }}</span>
                          </v-tooltip>
                        </v-card-actions>
                        <v-card-title class="pl-0"
                          >{{ $t('system.tasks.current') }}
                        </v-card-title>
                      </div>

                      <v-card-actions>
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-icon
                              key="add"
                              class="ant-icon mx-1"
                              @click="showFitGapTaskDialog = true"
                              v-on="on"
                            >
                              mdi-plus
                            </v-icon>
                          </template>
                          <span>{{ $t('system.tasks.createNew') }}</span>
                        </v-tooltip>
                      </v-card-actions>
                    </div>
                  </v-card>
                  <div v-if="itemTasks.length">
                    <fit-gap-task-item
                      v-for="task in itemTasks"
                      :key="task.id"
                      :task="task"
                      @click="onSelectTask"
                    />
                  </div>
                  <v-card v-else class="pa-0 mt-2">
                    <div
                      class="d-flex justify-space-between align-content-center"
                    >
                      <div
                        v-if="
                          $wait.is('module.fit-gap.tasks.get') &&
                          !itemTasks?.length
                        "
                        class="d-flex full-height align-center justify-center ma-auto"
                      >
                        <ant-loading />
                      </div>
                      <v-card-text v-else
                        >{{ $t('system.tasks.noTasksYet') }}
                      </v-card-text>
                    </div>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </div>
        </transition-group>
      </div>
    </div>
    <div v-else class="flex-grow-1 d-flex align-center justify-center">
      <ant-loading />
    </div>
    <fit-gap-dialog
      key="fit-gap-dialog"
      :fit-gap-item="selectedItem"
      :is-shown="showFitGapTaskDialog"
      :task="selectedTask"
      @closeDialog="onFitGapTaskDialogClose"
    />
  </div>
</template>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}

.v-expansion-panel::before {
  display: none;
}

.v-expansion-panel-header {
  background-color: #f8f8f8;

  &--active {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.v-expansion-panels .v-expansion-panel:not(:first-child) {
  .v-expansion-panel-header {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &::after {
    display: none;
  }
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--next-active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active
  + .v-expansion-panel {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active {
  border-radius: 0;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.sidebar {
  margin-left: 16px;
  //width: 500px;
  min-height: 600px;
  background-color: #f0f2f5;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.columns {
  column-gap: 1rem;
}

.column-center {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 50%;
}

.fit-column {
  width: 100px;
}
</style>
