<script>
import { defineComponent } from 'vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import AntInput from '@/components/AntInput.vue';
import { STAKEHOLDER_ANALYSIS } from '@/modules/modules';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import SwotItem from '@/components/Modules/StakeholderAnalyis/SwotItem.vue';

export default defineComponent({
  name: 'StakeholderAnalysis',
  components: {
    SwotItem,
    DynamicDataTable,
    DeleteDialog,
    AntLoading,
    AntInput,
    PanelResizable,
    ModuleNavigationBar,
  },
  mixins: [InputRulesMixin],
  data: () => {
    return {
      stakeholderCreateName: null,
      stakeholderCreateMenu: false,
      stakeholderToDelete: null,

      swotDialog: false,
      swotRecord: {},
      swotRecordToDelete: null,
      series: [],
      chartOptions: {},
      swotInputHeaders: [
        {
          text: 'id',
          value: 'friendly_id',
        },
        {
          text: 'title',
          value: 'title',
        },
        {
          text: 'description',
          value: 'description',
        },
        {
          text: 'type',
          value: 'type',
        },
        {
          text: 'weight',
          value: 'weight',
        },
        {
          text: 'actions',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'stakeHolderAnalysisStakeholders',
      'stakeholderAnalysisSwotInputRecords',
      'stakeholderAnalysisSwotRecords',
      'stakeholderAnalysisSwotTotalWeight',
      'stakeholderAnalysisStatus',
      'selectedStakeholder',
      'sbsRecords',
    ]),

    swotOpportunities() {
      return this.stakeholderAnalysisSwotRecords
        .filter((record) => record.type === 'opportunity')
        .sort((a, b) => b.weight - a.weight);
    },
    swotThreats() {
      return this.stakeholderAnalysisSwotRecords
        .filter((record) => record.type === 'threat')
        .sort((a, b) => b.weight - a.weight);
    },
    swotStrengths() {
      return this.stakeholderAnalysisSwotRecords
        .filter((record) => record.type === 'strength')
        .sort((a, b) => b.weight - a.weight);
    },
    swotWeakness() {
      return this.stakeholderAnalysisSwotRecords
        .filter((record) => record.type === 'weakness')
        .sort((a, b) => b.weight - a.weight);
    },
  },
  watch: {
    stakeholderAnalysisSwotRecords: {
      deep: true,
      handler(value) {
        if (value) {
          this.updateChartSeries();
        }
      },
    },
    stakeHolderAnalysisStakeholders: {
      deep: true,
      handler(value) {
        if (value) {
          this.updateChartSeries();
          this.updateChartOptions();
        }
      },
    },
  },
  mounted() {
    this.$store.dispatch('loadStakeHolderAnalysisModuleData', {
      projectId: this.project.id,
      moduleId: this.project.modules.find(
        (module) => module.route === STAKEHOLDER_ANALYSIS
      ).id,
      sessionId: this.$route.params.sessionId ?? null,
    });

    this.$store.dispatch('loadSbsRecords', { projectId: this.project.id });
  },
  methods: {
    stringToConstantColor,
    updateChartSeries() {
      this.series = this.stakeHolderAnalysisStakeholders.map((item) => {
        return {
          name: item.name,
          data: [
            [
              item.interest,
              item.influence,
              Math.round(
                (this.stakeholderAnalysisSwotRecords
                  .filter((item2) => item2.stakeholder_id === item.id)
                  .reduce(
                    (partial_sum, a) => partial_sum + parseInt(a.weight),
                    0
                  ) /
                  this.stakeholderAnalysisSwotTotalWeight) *
                  100
              ) + 50,
            ],
          ],
        };
      });
    },
    updateChartOptions() {
      this.chartOptions = {
        colors: this.stakeHolderAnalysisStakeholders.map((item) =>
          stringToConstantColor(item.id)
        ),
        title: {
          text: 'Stakeholder analyse',
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          tickAmount: 1,
          min: 0,
          max: 10,
          title: {
            text: 'Interest',
          },
        },
        yaxis: {
          min: 0,
          max: 10,
          tickAmount: 10,
          title: {
            text: 'Influence',
          },
        },
        grid: {
          show: true,
          position: 'back',
        },
      };
    },
    async createStakeholder() {
      await this.$store.dispatch(
        'createStakeholder',
        this.stakeholderCreateName
      );
      this.stakeholderCreateName = null;
      this.stakeholderCreateMenu = false;
    },
    async cancelCreateStakeholder() {
      this.stakeholderCreateName = null;
      this.stakeholderCreateMenu = false;
    },
    async deleteStakeholder() {
      await this.$store.dispatch(
        'deleteStakeholder',
        this.stakeholderToDelete.id
      );
      this.stakeholderToDelete = null;
    },
    async createSwotRecord() {
      if (this.$refs['swot-form'].validate()) {
        try {
          this.swotRecord.stakeholder_id = this.selectedStakeholder.id;
          this.swotRecord.weight = parseInt(this.swotRecord.weight);
          await this.$store.dispatch('createSwotRecord', this.swotRecord);
          this.swotDialog = false;
          this.swotRecord = {};
          this.$refs['swot-form'].reset();
        } catch (e) {
          this.$store.commit('showNotification', {
            content: e.message,
            color: 'error',
          });
        }
      }
    },
    async deleteSwotRecord() {
      await this.$store.dispatch(
        'deleteSwotRecord',
        this.swotRecordToDelete.id
      );
      this.swotRecordToDelete = null;
    },
    updateStakeholder(column, value) {
      let body = {};
      body[column] = this.selectedStakeholder[column] + value;

      this.$store.dispatch('updateStakeholder', body);
    },
  },
});
</script>

<template>
  <div class="d-flex flex-column overflow-y-auto">
    <module-navigation-bar title="Stakeholder analysis" />
    <div
      v-if="stakeholderAnalysisStatus === 'success'"
      class="flex-grow-1 d-flex overflow-y-auto"
    >
      <panel-resizable
        :collapsible="false"
        :default-width="400"
        :min-width="100"
        class="ant-glass-background full-height ant-border-right radius-0 overflow-hidden"
        side="left"
        style="border-radius: 0"
      >
        <div class="d-flex flex-column flex-grow-1 overflow-hidden">
          <v-subheader class="px-2 my-2" style="height: auto">
            <div class="text-subtitle-2 py-1">Stakeholders</div>
            <v-spacer />
            <v-menu
              v-model="stakeholderCreateMenu"
              :close-on-content-click="false"
            >
              <template #activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template #activator="{ on: tooltip }">
                    <v-icon
                      class="ant-icon"
                      dense
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>New stakeholder</span>
                </v-tooltip>
              </template>
              <v-card class="pa-2">
                <ant-input label="Name" top-margin="mt-0">
                  <template #input-field>
                    <v-text-field
                      v-model="stakeholderCreateName"
                      autofocus
                      dense
                      filled
                      hide-details
                      placeholder="name"
                      single-line
                      @keydown.esc="cancelCreateStakeholder"
                      @keydown.enter="createStakeholder"
                    />
                  </template>
                </ant-input>
              </v-card>
            </v-menu>
          </v-subheader>
          <delete-dialog
            :dialog="stakeholderToDelete !== null"
            :title="`Are you sure you want to delete ${stakeholderToDelete?.name}`"
            @closeDialog="stakeholderToDelete = null"
            @deleteAction="deleteStakeholder"
          />
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="stakeholder in stakeHolderAnalysisStakeholders"
                :key="stakeholder.id"
                class="stakeholder-item"
                @click="
                  $store.commit(
                    'module_stake_holder_analysis_toggle_stakeholder',
                    stakeholder
                  )
                "
              >
                <v-list-item-icon>
                  <v-icon :color="stringToConstantColor(stakeholder.id)"
                    >mdi-checkbox-blank-circle
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title> {{ stakeholder.name }}</v-list-item-title>
                <v-icon
                  class="stakeholder-delete-icon ant-icon"
                  dense
                  @click.stop="stakeholderToDelete = stakeholder"
                  >mdi-delete
                </v-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </panel-resizable>
      <div class="flex-grow-1 d-flex overflow-y-auto">
        <div class="swot-container pa-5 overflow-y-auto flex-grow-1">
          <div
            class="ant-glass-background d-flex flex-column overflow-hidden swot-card"
          >
            <div
              :style="{ background: 'var(--v-success-lighten4)' }"
              class="px-5 py-2 d-flex align-center justify-center ant-border-bottom"
            >
              Strengths
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div class="fs-12 ml-2" v-bind="attrs" v-on="on">
                    ({{
                      swotStrengths.reduce(
                        (partial_sum, a) => partial_sum + parseInt(a.weight),
                        0
                      )
                    }})
                  </div>
                </template>
                <span>Total weight</span>
              </v-tooltip>
            </div>
            <div class="pa-2 overflow-y-auto flex-grow-1">
              <swot-item
                v-for="record in swotStrengths"
                :key="record.id"
                :active="
                  selectedStakeholder
                    ? record.stakeholder_id === selectedStakeholder?.id
                    : true
                "
                :record="record"
              />
            </div>
          </div>
          <div
            class="ant-glass-background d-flex flex-column overflow-hidden swot-card"
          >
            <div
              :style="{ background: 'var(--v-warning-lighten4)' }"
              class="px-5 py-2 d-flex align-center justify-center ant-border-bottom"
            >
              Weakness
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div class="fs-12 ml-2" v-bind="attrs" v-on="on">
                    ({{
                      swotWeakness.reduce(
                        (partial_sum, a) => partial_sum + parseInt(a.weight),
                        0
                      )
                    }})
                  </div>
                </template>
                <span>Total weight</span>
              </v-tooltip>
            </div>
            <div class="pa-2 overflow-y-auto flex-grow-1">
              <swot-item
                v-for="record in swotWeakness"
                :key="record.id"
                :active="
                  selectedStakeholder
                    ? record.stakeholder_id === selectedStakeholder?.id
                    : true
                "
                :record="record"
              />
            </div>
          </div>
          <div
            class="ant-glass-background d-flex flex-column overflow-hidden swot-card"
          >
            <div
              :style="{ background: 'var(--v-info-lighten4)' }"
              class="px-5 py-2 d-flex align-center justify-center ant-border-bottom"
            >
              Opportunities
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div class="fs-12 ml-2" v-bind="attrs" v-on="on">
                    ({{
                      swotOpportunities.reduce(
                        (partial_sum, a) => partial_sum + parseInt(a.weight),
                        0
                      )
                    }})
                  </div>
                </template>
                <span>Total weight</span>
              </v-tooltip>
            </div>
            <div class="pa-2 overflow-y-auto flex-grow-1">
              <swot-item
                v-for="record in swotOpportunities"
                :key="record.id"
                :active="
                  selectedStakeholder
                    ? record.stakeholder_id === selectedStakeholder?.id
                    : true
                "
                :record="record"
              />
            </div>
          </div>
          <div
            class="ant-glass-background d-flex flex-column overflow-hidden swot-card"
          >
            <div
              :style="{ background: 'var(--v-error-lighten4)' }"
              class="px-5 py-2 d-flex align-center justify-center ant-border-bottom"
            >
              Threats
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div class="fs-12 ml-2" v-bind="attrs" v-on="on">
                    ({{
                      swotThreats.reduce(
                        (partial_sum, a) => partial_sum + parseInt(a.weight),
                        0
                      )
                    }})
                  </div>
                </template>
                <span>Total weight</span>
              </v-tooltip>
            </div>
            <div class="pa-2 overflow-y-auto flex-grow-1">
              <swot-item
                v-for="record in swotThreats"
                :key="record.id"
                :active="
                  selectedStakeholder
                    ? record.stakeholder_id === selectedStakeholder?.id
                    : true
                "
                :record="record"
              />
            </div>
          </div>
        </div>
        <div
          class="ant-glass-background ant-border-left radius-0 overflow-y-auto"
          style="width: 700px"
        >
          <div class="d-flex flex-column pa-5 flex-grow-1">
            <delete-dialog
              :dialog="swotRecordToDelete !== null"
              :title="`Are you sure you want to delete id '${swotRecordToDelete?.friendly_id}'`"
              @closeDialog="swotRecordToDelete = null"
              @deleteAction="deleteSwotRecord"
            />
            <Transition name="simple-fade">
              <dynamic-data-table
                v-if="selectedStakeholder"
                :auto-sort-column="'weight'"
                :table-headers="swotInputHeaders"
                :table-records="stakeholderAnalysisSwotInputRecords"
                :table-title="`${selectedStakeholder.name} SWOT
            input`"
                can-delete
                @deleteItem="swotRecordToDelete = $event"
              >
                <template #table-actions>
                  <v-dialog
                    v-model="swotDialog"
                    max-width="500px"
                    @keydown.esc="swotDialog = false"
                  >
                    <template #activator="{ on, attrs }">
                      <v-tooltip bottom>
                        <template #activator="{ on: onTooltip }">
                          <v-icon
                            :disabled="!selectedStakeholder"
                            class="ant-icon"
                            dense
                            v-on="{ ...on, ...onTooltip }"
                          >
                            mdi-plus
                          </v-icon>
                        </template>
                        <span>New record</span>
                      </v-tooltip>
                    </template>
                    <v-card>
                      <v-card-title
                        class="justify-center text-uppercase headline"
                      >
                        Record
                      </v-card-title>
                      <v-divider />
                      <div class="px-10 pb-5">
                        <v-form ref="swot-form" @submit.prevent>
                          <ant-input label="Id">
                            <template #input-field>
                              <v-text-field
                                v-model="swotRecord.friendly_id"
                                :rules="[rules.required]"
                                dense
                                filled
                                hide-details
                                placeholder="1"
                                single-line
                              />
                            </template>
                          </ant-input>
                          <ant-input label="Title">
                            <template #input-field>
                              <v-text-field
                                v-model="swotRecord.title"
                                :rules="[rules.required]"
                                dense
                                filled
                                hide-details
                                placeholder="title"
                                single-line
                              />
                            </template>
                          </ant-input>
                          <ant-input label="Description">
                            <template #input-field>
                              <v-text-field
                                v-model="swotRecord.description"
                                dense
                                filled
                                hide-details
                                placeholder="description"
                                single-line
                              />
                            </template>
                          </ant-input>
                          <div class="d-flex">
                            <ant-input class="flex-grow-2 mr-2" label="Type">
                              <template #input-field>
                                <v-select
                                  v-model="swotRecord.type"
                                  :items="[
                                    'strength',
                                    'weakness',
                                    'opportunity',
                                    'threat',
                                  ]"
                                  :rules="[rules.required]"
                                  dense
                                  filled
                                  hide-details
                                  placeholder="type"
                                  single-line
                                />
                              </template>
                            </ant-input>
                            <ant-input class="flex-grow-1" label="Weight">
                              <template #input-field>
                                <v-text-field
                                  v-model="swotRecord.weight"
                                  :max="100"
                                  :min="0"
                                  :rules="[rules.required]"
                                  dense
                                  filled
                                  hide-details
                                  placeholder="1"
                                  single-line
                                  type="number"
                                />
                              </template>
                            </ant-input>
                          </div>
                          <ant-input label="Sbscode">
                            <template #input-field>
                              <v-combobox
                                v-model="swotRecord.sbscode"
                                :items="sbsRecords"
                                dense
                                filled
                                hide-details
                                item-text="code"
                                placeholder="sbscode"
                                type="text"
                              >
                                <template #item="{ item }">
                                  <div
                                    v-if="item.label"
                                    class="d-flex align-center"
                                  >
                                    {{ item.label }}
                                    <v-divider class="mx-2" vertical />
                                    <span style="font-size: 11px; color: grey">
                                      {{ item.code }}
                                    </span>
                                  </div>
                                  <div v-else>
                                    {{ item.code }}
                                  </div>
                                </template>
                              </v-combobox>
                            </template>
                          </ant-input>
                        </v-form>
                      </div>
                      <v-card-actions
                        class="ant-border-top ant-dialog-actions-bg"
                      >
                        <v-spacer />
                        <v-btn
                          color="primary"
                          small
                          text
                          @click="swotDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          elevation="0"
                          small
                          @click="createSwotRecord"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </dynamic-data-table>
            </Transition>

            <v-divider class="my-5" />
            <apexchart
              :options="chartOptions"
              :series="series"
              class="ant-glass-background flex-grow-1"
              height="350px"
              type="bubble"
              width="100%"
            />
            <Transition name="simple-fade">
              <div
                v-if="selectedStakeholder"
                class="d-flex flex-column align-center"
              >
                <v-subheader
                  >Move stakeholder (interest:
                  {{ selectedStakeholder.interest }}, influence:
                  {{ selectedStakeholder.influence }})
                </v-subheader>
                <div class="d-flex align-center justify-center">
                  <v-btn
                    :disabled="selectedStakeholder.interest === 0"
                    color="white"
                    elevation="0"
                    tile
                    @click="updateStakeholder('interest', -1)"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="selectedStakeholder.interest === 10"
                    color="white"
                    elevation="0"
                    tile
                    @click="updateStakeholder('interest', 1)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="selectedStakeholder.influence === 0"
                    color="white"
                    elevation="0"
                    tile
                    @click="updateStakeholder('influence', -1)"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="selectedStakeholder.influence === 10"
                    color="white"
                    elevation="0"
                    tile
                    @click="updateStakeholder('influence', 1)"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex-grow-1 d-flex align-center justify-center">
      <ant-loading />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.swot-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;

  .swot-card {
    min-height: 250px;
  }
}

.stakeholder-item {
  .stakeholder-delete-icon {
    opacity: 0;
    transition: 200ms;
  }

  &:hover {
    .stakeholder-delete-icon {
      opacity: 1;
    }
  }
}
</style>
