<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import AntInput from '@/components/AntInput.vue';
import {
  categories,
  fitOptions,
  priorityOptions,
} from '@/modules/fit-gap/constants';

export default defineComponent({
  name: 'FitGapForm',
  components: {
    AntInput,
  },
  mixins: [InputRulesMixin],
  props: {
    selectedItem: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => {
    return {
      step: null,
      maxStep: 0,

      form: {
        friendly_id: '',
        category: '',
        desired_situation: '',
        current_situation: '',
        gap_description: '',
        gap_priority: '',
        fit: '',
      },

      priorityOptions: priorityOptions,
      categories: categories,
      fitOptions: fitOptions,
    };
  },
  computed: {
    ...mapGetters(['project', 'sbsRecords']),
    ...mapGetters({}),
  },
  watch: {
    step(step) {
      this.maxStep = Math.max(this.maxStep, step);
    },
    selectedItem(value) {
      if (value) {
        this.form = {
          friendly_id: this.selectedItem.friendly_id,
          category: this.selectedItem.category,
          desired_situation: this.selectedItem.desired_situation,
          current_situation: this.selectedItem.current_situation,
          gap_description: this.selectedItem.gap_description,
          gap_priority: this.selectedItem.gap_priority,
          fit: this.selectedItem.fit,
        };
        this.maxStep = 4;
      } else {
        this.form = {
          friendly_id: '',
          category: '',
          desired_situation: '',
          current_situation: '',
          gap_description: '',
          gap_priority: '',
          fit: '',
        };
        this.maxStep = 0;
      }
    },
  },
  mounted() {},
  methods: {
    stringToConstantColor,
    validateForm(ref, onSuccess) {
      if (this.$refs[ref]?.validate()) {
        onSuccess();
      }
    },
    back() {
      if (this.step > 1) this.step -= 1;
    },
    globalReset() {
      this.$refs.createGapStep1.reset();
      this.$refs.createGapStep2.reset();
      this.$refs.createGapStep3.reset();
      this.$refs.createGapStep4.reset();
      this.step = 1;
    },
    cancel() {
      this.$emit('onCancel');
      if (this.step === 1) {
        this.$refs.createGapStep1.reset();
      } else {
        this.globalReset();
      }
    },
    async createGap() {
      if (
        this.$refs.createGapStep1.validate() &&
        this.$refs.createGapStep2.validate() &&
        this.$refs.createGapStep3.validate() &&
        this.$refs.createGapStep4.validate()
      ) {
        await this.$store.dispatch('fitGap/createFitGap', this.form);
        this.$emit('onCreated');
        this.globalReset();
      }
    },
    async updateGap(key, value) {
      await this.$store.dispatch('fitGap/updateFitGap', {
        recordId: this.selectedItem.id,
        column: key,
        value,
      });
    },
    closeGap() {
      this.$emit('closeItem');
      this.globalReset();
    },
    async deleteGap() {
      await this.$store.dispatch('fitGap/deleteFitGap', {
        recordId: this.selectedItem.id,
      });
      this.closeGap();
    },
  },
});
</script>

<template>
  <div class="pb-2">
    <v-card>
      <v-card-title
        >{{ selectedItem ? 'Update' : 'Create' }} Gap
        <v-spacer />
        <v-btn v-if="selectedItem" icon @click="closeGap">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-menu v-if="selectedItem" left>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>

          <v-list style="width: 350px" two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Are you sure you want to delete this item?
                </v-list-item-title>
                <v-list-item-subtitle
                  >This action cannot be undone
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div class="d-flex px-5">
              <v-spacer />
              <v-btn color="primary" small @click="deleteGap">Delete</v-btn>
            </div>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-stepper v-model="step" non-linear vertical>
              <v-stepper-step
                :complete="step > 1"
                :editable="maxStep >= 1"
                step="1"
              >
                Define the Desired Situation
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-alert color="info" prominent text>
                  <small>
                    In this step, you need to select the category that best fits
                    the situation you are analyzing. Then, describe the desired
                    situation, i.e., how you envision the situation should be
                    ideally.
                  </small>
                </v-alert>

                <v-form
                  ref="createGapStep1"
                  @submit.prevent="
                    validateForm('createGapStep1', () => (step = 2))
                  "
                >
                  <ant-input v-if="selectedItem" label="Number" top-margin="">
                    <template #input-field>
                      <v-text-field
                        v-model="form.friendly_id"
                        :rules="[rules.required]"
                        dense
                        filled
                        required
                        single-line
                        type="number"
                        @change="updateGap('friendly_id', $event)"
                      ></v-text-field>
                    </template>
                  </ant-input>
                  <ant-input label="Category" top-margin="">
                    <template #input-field>
                      <v-select
                        v-model="form.category"
                        :items="categories"
                        :rules="[rules.required]"
                        dense
                        filled
                        name="category"
                        required
                        single-line
                        @change="updateGap('category', $event)"
                      ></v-select>
                    </template>
                  </ant-input>

                  <ant-input label="Desired situation" top-margin="mt-2">
                    <template #input-field>
                      <v-textarea
                        v-model="form.desired_situation"
                        :rules="[rules.required]"
                        dense
                        filled
                        name="desired_situation"
                        placeholder="Desired situation..."
                        single-line
                        @change="updateGap('desired_situation', $event)"
                      />
                    </template>
                  </ant-input>

                  <v-btn class="ml-2" color="primary" type="submit">
                    {{ selectedItem ? 'Next' : 'Continue' }}
                  </v-btn>
                  <v-btn
                    v-if="!selectedItem"
                    class="float-end"
                    text
                    @click="cancel"
                  >
                    Cancel
                  </v-btn>
                </v-form>
              </v-stepper-content>

              <v-stepper-step
                :complete="step > 2"
                :editable="maxStep >= 2"
                step="2"
              >
                Describe the Current Situation
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-alert color="info" prominent text>
                  <small>
                    Here, you need to provide a detailed description of the
                    current situation. This should be an accurate representation
                    of the existing state of affairs.
                  </small>
                </v-alert>

                <v-form
                  ref="createGapStep2"
                  @submit.prevent="
                    validateForm('createGapStep2', () => (step = 3))
                  "
                >
                  <ant-input
                    is-optional
                    label="Current situation"
                    top-margin="mt-2"
                  >
                    <template #input-field>
                      <v-textarea
                        v-model="form.current_situation"
                        autofocus
                        dense
                        filled
                        name="current_situation"
                        placeholder=""
                        single-line
                        @change="updateGap('current_situation', $event)"
                      />
                    </template>
                  </ant-input>

                  <v-btn text @click="back"> Back</v-btn>
                  <v-btn class="ml-2" color="primary" type="submit">
                    {{ selectedItem ? 'Next' : 'Continue' }}
                  </v-btn>
                  <v-btn
                    v-if="!selectedItem"
                    class="float-end"
                    text
                    @click="cancel"
                  >
                    Cancel
                  </v-btn>
                </v-form>
              </v-stepper-content>

              <v-stepper-step
                :complete="step > 3"
                :editable="maxStep >= 3"
                step="3"
              >
                Identify the Gap
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-alert color="info" prominent text>
                  <small>
                    This step involves describing the gap between the current
                    and desired situation. The gap description should clearly
                    highlight the differences and areas that need improvement.
                  </small>
                </v-alert>

                <v-form
                  ref="createGapStep3"
                  @submit.prevent="
                    validateForm('createGapStep3', () => (step = 4))
                  "
                >
                  <ant-input
                    is-optional
                    label="Gap description"
                    top-margin="mt-2"
                  >
                    <template #input-field>
                      <v-textarea
                        v-model="form.gap_description"
                        autofocus
                        dense
                        filled
                        name="gap_description"
                        placeholder="Gap description..."
                        single-line
                        @change="updateGap('gap_description', $event)"
                      />
                    </template>
                  </ant-input>

                  <v-btn text @click="back"> Back</v-btn>
                  <v-btn class="ml-2" color="primary" type="submit">
                    {{ selectedItem ? 'Next' : 'Continue' }}
                  </v-btn>
                  <v-btn
                    v-if="!selectedItem"
                    class="float-end"
                    text
                    @click="cancel"
                  >
                    Cancel
                  </v-btn>
                </v-form>
              </v-stepper-content>

              <v-stepper-step :editable="maxStep > 3" step="4">
                Prioritize and Evaluate
              </v-stepper-step>
              <v-stepper-content step="4">
                <v-alert color="info" prominent text>
                  <small>
                    The final step involves setting the priority and severity of
                    the gap.<br />
                    The priority indicates how urgently the gap needs to be
                    addressed, while the severity represents the impact of the
                    gap on the overall situation.<br />
                    Additionally, you need to evaluate the 'fit', which
                    represents how well the proposed solution fits the gap.
                  </small>
                </v-alert>

                <v-form
                  ref="createGapStep4"
                  @submit.prevent="validateForm('createGapStep4', createGap)"
                >
                  <ant-input is-optional label="Priority" top-margin="">
                    <template #input-field>
                      <v-autocomplete
                        v-model="form.gap_priority"
                        :items="priorityOptions"
                        auto-select-first
                        dense
                        filled
                        name="gap_priority"
                        required
                        single-line
                        @change="updateGap('gap_priority', $event)"
                      />
                    </template>
                  </ant-input>

                  <ant-input is-optional label="Fit" top-margin="">
                    <template #input-field>
                      <v-autocomplete
                        v-model="form.fit"
                        :items="fitOptions"
                        auto-select-first
                        dense
                        filled
                        name="fit"
                        required
                        single-line
                        @change="updateGap('fit', $event)"
                      />
                    </template>
                  </ant-input>

                  <v-btn text @click="back"> Back</v-btn>
                  <v-btn
                    v-if="!selectedItem"
                    :loading="$wait.is('module.fit-gap.item.create')"
                    class="ml-2"
                    color="primary"
                    type="submit"
                  >
                    Create
                  </v-btn>
                  <v-btn
                    v-if="!selectedItem"
                    class="float-end"
                    text
                    @click="cancel"
                  >
                    Cancel
                  </v-btn>
                </v-form>
              </v-stepper-content>
            </v-stepper>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<style lang="scss" scoped></style>
