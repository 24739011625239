<script>
import AntInput from '@/components/AntInput.vue';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import { getProjectMembersWithTaskPermissions } from '@/services/api/projects.api';
import TaskHelper from '@/services/task-helper';
import TaskLabel from '@/components/Tasks/TaskLabel.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FitGapDialog',
  components: { TaskLabel, AntInput },
  props: {
    returnCreate: {
      type: Boolean,
      default: false,
    },
    fitGapItem: {
      type: Object,
      default: null,
    },
    task: {
      type: Object,
      default: null,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      isPrivate: false,
      today: moment().format('YYYY-MM-DD'),
      innerTask: {
        priority: null,
        title: null,
        labels: [],
        options: {
          type: 'fit-gap',
        },
      },
      users: [],
      menus: {
        start_date: false,
      },
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          value ? value.length < 250 || 'Max 250 characters' : true,
      },
      fitGapRequestStatus: '',
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser', 'project', 'selectedLicense']),
    isEditing() {
      return !!this.task;
    },
    canUpdate() {
      return (
        !this.isEditing ||
        ((this.$can('update', 'fit-gap-task') ||
          this.authenticatedUser?.id === this.innerTask?.assigned_to?.id) &&
          this.innerTask?.status === 'open')
      );
    },
    titleText() {
      const mode = this.isEditing
        ? this.canUpdate
          ? this.$t('general.edit')
          : null
        : this.$t('general.create');
      const number = this.innerTask?.number
        ? `(${this.innerTask?.number})`
        : null;
      return [mode, 'Fit Gap Task', number].filter(Boolean).join(' ');
    },
  },
  watch: {
    async isShown(value) {
      if (!value) {
        this.closeDialog();
        return;
      }
      if (!this.project) {
        this.closeDialog();
        this.$store.commit('showNotification', {
          content: this.$t('system.tasks.notifications.noProject'),
          color: 'error',
        });
        return;
      }

      this.innerTask.project = this.project;
      this.innerTask.priority = 'normal';

      if (this.isEditing) {
        this.innerTask = {
          title: this.task.title,
          project: this.task.task_project,
          priority: this.task.priority,
          description: this.task.description,
          assigned_user: this.task.assigned_to,
          due: moment(this.task.due).format('YYYY-MM-DD'),
          license: this.task.license,
          status: this.task.status,
          labels: this.task.labels,
          options: {
            type: 'fit-gap',
            custom_1: this.task.task_type.fit_gap_item_id,
          },
        };
      }

      this.users = await getProjectMembersWithTaskPermissions(
        this.innerTask.project.id
      );
    },
    fitGapItem: {
      handler(value) {
        if (value) {
          this.innerTask.options.custom_1 = value.id;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    unsetSubscribedUser() {
      if (this.canUpdate) {
        this.innerTask.assigned_user = null;
      }
    },
    closeDialog() {
      if (this.fitGapRequestStatus !== 'loading') {
        this.$emit('closeDialog');
        this.$refs['create-fit-gap-form'].reset();
        this.fitGapRequestStatus = '';
        this.innerTask = {
          priority: null,
          title: null,
          options: {
            type: 'fit-gap',
          },
        };
      }
    },
    async onFitGapTaskSubmit() {
      if (this.$refs['create-fit-gap-form'].validate()) {
        if (!this.innerTask.title) {
          this.$store.commit('showNotification', {
            content: this.$t('system.tasks.notifications.fillTitle'),
            color: 'error',
          });
          return;
        }
        if (!this.innerTask.assigned_user) {
          this.$store.commit('showNotification', {
            content: this.$t('system.tasks.notifications.addUser'),
            color: 'error',
          });
          return;
        }

        let body = {
          project: this.innerTask.project.id,
          title: this.innerTask.title,
          priority: this.innerTask.priority,
          description: this.innerTask.description,
          assigned_to:
            this.innerTask.assigned_user.id || this.innerTask.assigned_to.id,
          due: moment(this.innerTask.due).format('YYYY-MM-DD HH:mm:ss'),
          license: this.selectedLicense.id,
          labels: this.innerTask?.labels?.map((l) => l.id),
          options: this.innerTask.options,
        };

        if (this.isEditing) {
          this.fitGapRequestStatus = 'loading';

          this.$store
            .dispatch('updateTask', { taskId: this.task.id, body })
            .then((task) => {
              this.fitGapRequestStatus = 'success';
              this.$store.commit('showNotification', {
                content: this.$t('system.tasks.notifications.updateTask', {
                  title: task.title,
                }),
                color: 'success',
              });
              this.$store.commit('fitGap/module_fit_gap_update_task_success', {
                fitGapItemId: this.fitGapItem.id,
                task,
              });
              this.closeDialog();
            })
            .catch((error) => {
              this.fitGapRequestStatus = 'error';
            });
        } else {
          this.fitGapRequestStatus = 'loading';

          this.$store
            .dispatch('createTask', { body })
            .then((task) => {
              this.fitGapRequestStatus = 'success';
              this.$store.commit('showNotification', {
                content: this.$t('system.tasks.notifications.createTask', {
                  title: task.title,
                }),
                color: 'success',
              });
              this.$store.commit('fitGap/module_fit_gap_create_task_success', {
                fitGapItemId: this.fitGapItem.id,
                task,
              });
              this.closeDialog();
            })
            .catch((error) => {
              this.fitGapRequestStatus = 'error';
            });
        }
      }
    },
    getPriorityIcon(priority) {
      return TaskHelper.getPriorityIcon(priority);
    },
    getPriorityColor(priority) {
      return TaskHelper.getPriorityColor(priority);
    },
    async cancelTask() {
      await this.$store.dispatch('cancelTask', this.task.id);
      this.innerTask.status = 'canceled';
    },
    async closeOrOpenTask() {
      if (this.innerTask.status === 'open') {
        await this.$store.dispatch('closeTask', this.task.id);
        this.innerTask.status = 'closed';
      } else {
        await this.$store.dispatch('reopenTask', this.task.id);
        this.innerTask.status = 'open';
      }
    },
    onLabelChange(labels) {
      this.innerTask.labels = labels;
    },
  },
});
</script>

<template>
  <v-dialog
    :value="isShown"
    max-width="600px"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        <div class="d-flex justify-space-between flex-grow-1">
          <span>
            {{ titleText }}
          </span>

          <router-link
            v-if="task?.id && task?.id"
            :to="`/tasks/${task.id}`"
            class="task-link"
            target="_blank"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="ant-icon"
                  color="primary"
                  dense
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-launch
                </v-icon>
              </template>
              <span>{{ $t('general.openInNewTab') }}</span>
            </v-tooltip>
          </router-link>
        </div>
      </v-card-title>
      <v-divider />
      <div class="d-flex">
        <v-form ref="create-fit-gap-form" class="flex-1">
          <div class="px-10 pb-5">
            <v-row class="d-flex">
              <v-col class="pa-0 pl-3" cols="12">
                <ant-input
                  :label="$t('general.title') | capitalize"
                  class="mr-2"
                  style="flex: 1"
                >
                  <template #input-field>
                    <div class="d-flex">
                      <v-text-field
                        v-model="innerTask.title"
                        :disabled="!canUpdate"
                        :placeholder="$t('general.title')"
                        :rules="[rules.required]"
                        dense
                        filled
                        hide-details
                      />
                    </div>
                  </template>
                </ant-input>
              </v-col>
            </v-row>

            <v-row class="d-flex">
              <v-col class="pa-0 pl-3" cols="6">
                <ant-input
                  :label="$t('general.priority') | capitalize"
                  class="mr-2"
                  style="flex: 1"
                >
                  <template #input-field>
                    <v-select
                      v-model="innerTask.priority"
                      :disabled="!canUpdate"
                      :items="['low', 'normal', 'high', 'urgent']"
                      :placeholder="$t('general.priority') | capitalize"
                      :rules="[rules.required]"
                      dense
                      filled
                      hide-details
                    >
                      <template #selection="{ item, index }">
                        <v-icon :color="getPriorityColor(item)" class="mr-2"
                          >{{ getPriorityIcon(item) }}
                        </v-icon>
                        {{ item }}
                      </template>
                      <template #item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-icon>
                            <v-icon :color="getPriorityColor(item)"
                              >{{ getPriorityIcon(item) }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </template>
                </ant-input>
              </v-col>
              <v-col class="pa-0 pr-3" cols="6">
                <ant-input :label="`${$t('system.tasks.dueDate')}`">
                  <template #input-field>
                    <v-menu
                      v-model="menus.due_date"
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="innerTask.due"
                          :disabled="!canUpdate"
                          :placeholder="today"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          hide-details
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="innerTask.due"
                        no-title
                        scrollable
                        @input="menus.due_date = false"
                      />
                    </v-menu>
                  </template>
                </ant-input>
              </v-col>
            </v-row>
            <v-row cols="12">
              <task-label
                :editing="canUpdate"
                :labels="innerTask.labels"
                :project-id="innerTask?.project?.id"
                class="px-3 mt-5"
                @on-change="onLabelChange"
              />
            </v-row>
            <ant-input :label="`${$t('system.tasks.executedBy')}`">
              <template #input-field>
                <transition mode="out-in" name="simple-fade">
                  <v-edit-dialog v-if="!innerTask.assigned_user" persistent>
                    <v-icon class="ant-icon"> mdi-plus</v-icon>
                    <template #input>
                      <v-combobox
                        v-model="innerTask.assigned_user"
                        :disabled="!innerTask.project"
                        :items="users"
                        item-text="email"
                        item-value="id"
                        placeholder="email"
                        return-object
                        single-line
                      />
                    </template>
                  </v-edit-dialog>
                  <div v-else class="d-flex align-center">
                    <v-avatar
                      :color="
                        !canUpdate ? 'var(--v-secondary-lighten3)' : 'primary'
                      "
                      class="mr-2 white--text"
                      size="30"
                    >
                      {{
                        `${innerTask.assigned_user.firstname.charAt(
                          0
                        )}${innerTask.assigned_user.lastname.charAt(0)}`
                      }}
                    </v-avatar>
                    <div
                      :style="{
                        fontSize: '12px',
                        color: !canUpdate
                          ? 'var(--v-secondary-lighten3)'
                          : 'inherit',
                      }"
                      class="d-flex align-center"
                    >
                      {{ innerTask.assigned_user.email }}
                      <v-icon
                        v-if="canUpdate"
                        :class="[canUpdate ? 'ant-icon' : 'icon-disabled']"
                        small
                        @click="unsetSubscribedUser"
                        >mdi-close
                      </v-icon>
                    </div>
                  </div>
                </transition>
              </template>
            </ant-input>
            <slot name="add-task-options" />
            <ant-input
              :label="`${$t('general.description')}` | capitalize"
              is-optional
            >
              <template #input-field>
                <v-textarea
                  v-model="innerTask.description"
                  :disabled="!canUpdate"
                  :placeholder="$t('system.tasks.addDescription')"
                  :rules="[rules.maxLength]"
                  filled
                  hide-details
                />
              </template>
            </ant-input>
            <ant-input
              v-if="authenticatedUser?.id === task?.assigned_to?.id"
              label="Status"
            >
              <template #input-field>
                <div class="d-flex">
                  <v-btn
                    v-if="innerTask.status === 'open'"
                    class="mr-2"
                    color="warning"
                    elevation="0"
                    outlined
                    small
                    tile
                    @click="cancelTask"
                    >cancel
                  </v-btn>
                  <v-btn
                    :color="innerTask.status === 'open' ? 'success' : ''"
                    elevation="0"
                    outlined
                    small
                    tile
                    @click="closeOrOpenTask"
                    >{{ innerTask.status === 'open' ? 'complete' : 'reopen' }}
                  </v-btn>
                </div>
              </template>
            </ant-input>
            <v-spacer />
          </div>
        </v-form>
      </div>

      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn
          :disabled="fitGapRequestStatus === 'loading'"
          color="error"
          small
          text
          @click.stop="closeDialog"
        >
          {{ $t('general.cancel') }}
        </v-btn>
        <v-btn
          :disabled="!canUpdate"
          :loading="fitGapRequestStatus === 'loading'"
          color="primary"
          elevation="0"
          small
          @click.stop="onFitGapTaskSubmit"
        >
          {{ isEditing ? $t('general.save') : $t('general.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.icon-disabled {
  &:hover {
    cursor: default;
  }
}
</style>
