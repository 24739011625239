<script>
import { defineComponent } from 'vue';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';

export default defineComponent({
  name: 'SwotItem',
  props: {
    record: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isMenuOpen: false,
  }),
  computed: {
    api() {
      return {
        close: this.close,
      };
    },
  },
  methods: {
    stringToConstantColor,
    close() {
      this.isMenuOpen = false;
    },
  },
});
</script>

<template>
  <div
    :style="{
      opacity: active ? 1 : 0.4,
    }"
    class="d-flex flex-grow-1 align-center mb-2"
  >
    <v-icon
      :color="stringToConstantColor(record.stakeholder_id)"
      class="mr-2"
      small
      >mdi-checkbox-blank-circle
    </v-icon>

    <div class="flex-grow-1">
      {{ record.title }}
    </div>

    <div class="d-flex">
      <v-divider class="ml-2" vertical />
      <span class="ml-2 font-italic fs-10" style="width: 20px">{{
        record.weight
      }}</span>

      <slot name="append" v-bind="{ isMenuOpen, api }"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
